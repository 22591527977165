// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-discography-index-js": () => import("./../../../src/pages/music/discography/index.js" /* webpackChunkName: "component---src-pages-music-discography-index-js" */),
  "component---src-pages-music-index-js": () => import("./../../../src/pages/music/index.js" /* webpackChunkName: "component---src-pages-music-index-js" */),
  "component---src-pages-music-teaching-resources-index-js": () => import("./../../../src/pages/music/teaching-resources/index.js" /* webpackChunkName: "component---src-pages-music-teaching-resources-index-js" */),
  "component---src-pages-other-index-js": () => import("./../../../src/pages/other/index.js" /* webpackChunkName: "component---src-pages-other-index-js" */),
  "component---src-pages-reading-list-index-js": () => import("./../../../src/pages/reading-list/index.js" /* webpackChunkName: "component---src-pages-reading-list-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-tech-index-js": () => import("./../../../src/pages/tech/index.js" /* webpackChunkName: "component---src-pages-tech-index-js" */),
  "component---src-pages-tech-projects-index-js": () => import("./../../../src/pages/tech/projects/index.js" /* webpackChunkName: "component---src-pages-tech-projects-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

